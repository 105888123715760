// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d4wnLBRrsmUGgt9obB8b{font-family:Inter Regular,sans-serif}.t9qPwuewScRuhDuug4e4{font-family:Inter Medium,sans-serif}.lnHABxS8MS8qbXPGd81r{font-family:Inter SemiBold,sans-serif}.cpuWSNZdSML07OdnElUt{font-family:DMMono Regular,monospace}.DdBnIY4Vv5YiJ71MeLld{clip:rect(0 0 0 0);clip-path:inset(50%);height:1px;overflow:hidden;position:absolute;white-space:nowrap;width:1px;-webkit-user-select:none;-moz-user-select:none;user-select:none}.DdBnIY4Vv5YiJ71MeLld input{caret-color:transparent}.blyxlpUI0nk81GEiAQiX{pointer-events:none}.dWIz8WNTXVMR2tmcQwgh{cursor:text;-webkit-user-select:text;-moz-user-select:text;user-select:text}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"primary": "#d48343",
	"disabled": "var(--color-black-005)",
	"success": "#5cb56a",
	"tsuccess": "#fff",
	"tprimary": "#fff",
	"secondary": "#e34f62",
	"tsecondary": "#fff",
	"default": "#f2f2f2",
	"tdefault": "#000",
	"info": "#2f80ed",
	"tinfo": "#fff",
	"primary-light": "#e9a848",
	"tprimary-light": "#fff",
	"secondary-light": "#ec5b5b",
	"tsecondary-light": "#fff",
	"danger": "#c25555",
	"tdanger": "#fff",
	"draft": "#dedede",
	"black": "#13171a",
	"white": "#fff",
	"grey": "#e8ebed",
	"darkGrey": "#3a4751",
	"error": "#bc0000",
	"terror": "#fff",
	"BGlabel": "#f7f7f7",
	"default-status-color": "rgba(0,0,0,.05)",
	"tdefault-status-color": "rgba(0,0,0,.5)",
	"bdefault-status-color": "rgba(0,0,0,.2)",
	"blocked-status-color": "#f2f2f2",
	"tblocked-status-color": "rgba(0,0,0,.5)",
	"bblocked-status-color": "rgba(0,0,0,.2)",
	"inactive-status-color": "rgba(0,0,0,.05)",
	"tinactive-status-color": "rgba(0,0,0,.5)",
	"binactive-status-color": "rgba(0,0,0,.2)",
	"cancelled-status-color": "#f2f2f2",
	"tcancelled-status-color": "rgba(0,0,0,.5)",
	"bcancelled-status-color": "rgba(0,0,0,.2)",
	"primary-status-color": "#f19436",
	"tprimary-status-color": "#fff",
	"info-status-color": "#e5f4fd",
	"tinfo-status-color": "#3d7eb1",
	"binfo-status-color": "rgba(61,126,177,.2)",
	"secondary-status-color": "#5d9d52",
	"tsecondary-status-color": "#fff",
	"success-status-color": "#e6f9d8",
	"tsuccess-status-color": "#236400",
	"bsuccess-status-color": "rgba(35,100,0,.2)",
	"deleted-status-color": "rgba(167,0,0,.5)",
	"tdeleted-status-color": "#fff",
	"danger-status-color": "#fcebcc",
	"tdanger-status-color": "#784a20",
	"bdanger-status-color": "rgba(120,74,32,.2)",
	"bounced-status-color": "#f9e2e2",
	"tbounced-status-color": "#862a25",
	"bbounced-status-color": "rgba(134,42,37,.2)",
	"white-status-color": "#fff",
	"twhite-status-color": "rgba(0,0,0,.5)",
	"bwhite-status-color": "rgba(0,0,0,.2)",
	"draft-status-color": "#dedede",
	"disabled-status-color": "#f9e2e2",
	"pre_new-status-color": "#dedede",
	"pending-status-color": "#dedede",
	"error-status-color": "rgba(167,0,0,.5)",
	"terror-status-color": "#fff",
	"ADMINsuccess-status-color": "#e6f9d8",
	"tADMINsuccess-status-color": "#5d9d52",
	"bADMINsuccess-status-color": "rgba(35,100,0,.2)",
	"ADMINinactive-status-color": "#fcebcc",
	"tADMINinactive-status-color": "#784a20",
	"bADMINinactive-status-color": "rgba(120,74,32,.2)",
	"ADMINdanger-status-color": "rgba(0,0,0,.05)",
	"tADMINdanger-status-color": "#797979",
	"bADMINdanger-status-color": "rgba(0,0,0,.2)",
	"ADMINdeleted-status-color": "#fff",
	"tADMINdeleted-status-color": "#999",
	"bADMINdeleted-status-color": "rgba(0,0,0,.2)",
	"mobileMaxWidth": "499px",
	"mobileMinWidth": "320px",
	"mobileheaderHeight": "52px",
	"desktopMinWidth": "500px",
	"font-regular": "d4wnLBRrsmUGgt9obB8b",
	"font-medium": "t9qPwuewScRuhDuug4e4",
	"font-semibold": "lnHABxS8MS8qbXPGd81r",
	"font-mono": "cpuWSNZdSML07OdnElUt",
	"visually-hidden": "DdBnIY4Vv5YiJ71MeLld",
	"no-events": "blyxlpUI0nk81GEiAQiX",
	"selectable": "dWIz8WNTXVMR2tmcQwgh"
};
export default ___CSS_LOADER_EXPORT___;
