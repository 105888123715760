import { defineStore } from 'pinia';

const initState = {
  invoices: [],
  deposits: [],
  historyStartedYear: null,
  isInvoicesHistoryLoaded: false,
  isDepositsHistoryLoaded: false,
};

export const useBillingHistoryStore = defineStore('billing-history', {
  state: () => (initState),
  actions: {
    setInvoicesHistory(items) {
      this.invoices = items;
    },
    setInvoicesHistoryLoaded(value) {
      this.isInvoicesHistoryLoaded = value;
    },
    setDepositsHistory(items) {
      this.deposits = items;
    },
    setDepositsHistoryLoaded(value) {
      this.isDepositsHistoryLoaded = value;
    },
    setHistoryStartedYear(year) {
      this.historyStartedYear = year;
    },
    clearState() {
      this.state = initState;
    },
  },
  persist: true,
});
