import { computed } from 'vue';
import { defineStore } from 'pinia';

import { useStore } from './useStore';

export const temporaryStore = defineStore('temporary', {
  state: () => ({
    registration: {
      uid: null,
      email: null,
      step: 1,
      companyName: null,
      currency: null,
      revokeAt: null,
    },

    adminRegistration: {
      step: 1,
    },

    merchantLogin: {
      uid: null,
      step: 'account', // ['account', 'code', 'recovery','detonation'];
    },

    accessToken: null,
    refreshToken: null,
    deviceId: null,

    tokens: [],
    importWallets: [],
  }),

  actions: {
    clearRegistrationState() {
      this.registration = {
        uid: null,
        email: null,
        step: 1,
        companyName: null,
        currency: null,
        revokeAt: null,
      };

      this.merchantLogin = {
        step: 'account',
      };

      this.accessToken = null;
      this.refreshToken = null;
      this.deviceId = null;
    },
    updateStoreStep(step) {
      this.registration.step = step;
    },
    updateRegistrationState(state) {
      this.$patch({
        registration: { ...state },
      });
    },
    updateAdminRegistrationState(state) {
      this.$patch({
        adminRegistration: { ...state },
      });
    },
    updateMerchantLoginState(state) {
      this.$patch({
        merchantLogin: { ...state },
      });
    },
    updateTokens({ accessToken: aToken, refreshToken: rToken }) {
      this.$patch({
        accessToken: aToken,
        refreshToken: rToken,
      });
    },
    updateImportWallets(val, isAdd) {
      const exceptionValues = ['ETH_ETH', 'ERC-20'];

      if (isAdd) {
        if (exceptionValues.includes(val)) {
          this.importWallets = [...this.importWallets, ...exceptionValues];
        } else {
          this.importWallets.push(val);
        }
      } else if (exceptionValues.includes(val)) {
        this.importWallets = this.importWallets.filter((item) => !exceptionValues.includes(item));
      } else {
        this.importWallets = this.importWallets.filter((item) => item !== val);
      }
    },
  },

  persist: true,
});

export const useTemporaryStore = () => {
  const store = temporaryStore();

  const accessToken = computed(() => store.accessToken);
  const refreshToken = computed(() => store.refreshToken);
  const deviceId = computed(() => store.deviceId);
  const registration = computed(() => store.registration);
  const currentStep = computed(() => registration.value?.step);
  const userFromToken = computed(() => (accessToken.value
    ? JSON.parse(atob(accessToken.value.split('.')[1]))
    : {}));
  const merchantId = computed(() => userFromToken.value?.id);
  const importWallets = computed(() => store.importWallets);

  const setNextStep = () => {
    store.updateStoreStep(currentStep.value + 1);
  };

  const replaceTemporaryStoreWithMain = ({ role = 'merchant', uid, response }) => {
    const aToken = response?.access?.token ?? accessToken.value;
    const rToken = response?.refresh?.token ?? refreshToken.value;

    const mainStore = useStore();
    mainStore.$patch({
      role,
      uid,
      authToken: aToken,
      refreshToken: rToken,
      deviceId: deviceId.value,
    });
  };

  const adminRegistration = computed(() => store.adminRegistration);

  const merchantLogin = computed(() => store.merchantLogin);

  const replaceAccessToken = (role = 'merchant') => {
    const mainStore = useStore();
    mainStore.$patch({
      role,
      authToken: accessToken.value,
      deviceId: deviceId.value,
    });
  };

  const replaceRefreshToken = () => {
    const mainStore = useStore();
    mainStore.$patch({
      refreshToken: refreshToken.value,
    });
  };

  return {
    store,
    currentStep,

    accessToken,
    refreshToken,
    deviceId,
    registration,
    merchantId,

    replaceTemporaryStoreWithMain,

    adminRegistration,
    replaceAccessToken,
    replaceRefreshToken,
    setNextStep,
    merchantLogin,
    importWallets,
  };
};
