import { defineStore } from 'pinia';

import { dateFormats } from '@/common/data';

export const useAdminStore = defineStore('admin', {
  state: () => ({
    admin: {},
    settings: {},
  }),
  actions: {
    initProfile(data) {
      this.admin = data;
      if (data.dateFormat === null) {
        this.admin.dateFormat = dateFormats.value[0].value;
      }
    },
    clearProfile() {
      this.$reset();
    },
  },

  persist: true,
});
