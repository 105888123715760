import * as Sentry from '@sentry/vue';

export const initSentry = (app) => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  app.config.globalProperties.errorHandler = (error) => Sentry.captureException(error);

  Sentry.init({
    app,
    dsn: process.env.VUE_APP_SENTRY_DSN,
  });
};
