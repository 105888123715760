import { computed } from 'vue';

import { APP_ROLES } from '@/common/data';
import { useStore, useTemporaryStore } from '@/store';
import { useToken } from '@/composables/useToken';
import { useWallet } from '@/composables/useWallet';

import { actions } from './actions';

export const guards = (toRoute, fromRoute) => {
  const routeRoles = toRoute.meta?.roles || [APP_ROLES.USER];
  const {
    authToken, tokenRole, isAdminRole, isMerchantRole,
  } = useToken();
  const isUserLoggedIn = (Boolean(authToken.value));
  const dashboardPage = (() => {
    if (isAdminRole.value) {
      return '/sales';
    }
    if (isMerchantRole.value) {
      return '/dashboard';
    }

    return '/';
  })();
  const homePage = isUserLoggedIn ? dashboardPage : '/';

  const checkHomePage = (path) => {
    if (path === '/' || path === homePage) {
      return homePage;
    }
    return path;
  };

  const checkCommonPages = (path) => {
    if (!isUserLoggedIn) {
      const isCommonPage = routeRoles.includes(APP_ROLES.USER);
      const isAdminAuthPage = toRoute.meta?.layout === 'AdminAuth';
      const isEmptyLayout = toRoute.meta?.layout === 'EmptyLayout';

      if (isCommonPage || isAdminAuthPage || isEmptyLayout) {
        return path;
      }
      return homePage;
    }
    return path;
  };

  const checkRelatedRolesToPages = (path) => {
    if (routeRoles.includes(tokenRole.value) || routeRoles.includes(APP_ROLES.USER)) {
      return path;
    }

    return homePage;
  };

  const checkDisabledPages = async (path) => {
    const store = useStore();

    if (path === '/sign-up') {
      if (store.preferences?.isSignUpEnabled === null) {
        const { readGlobalSettings } = actions(toRoute);
        await readGlobalSettings();
      }
      const isSignUpEnabled = computed(() => store.preferences?.isSignUpEnabled);
      if (!isSignUpEnabled.value) {
        return homePage;
      }
    }

    if (path === '/session-expired') {
      const { registration } = useTemporaryStore();
      const hasNoCompanyName = !registration.value.companyName;
      const navigateFromRefresh = fromRoute.path === '/';
      if (hasNoCompanyName || navigateFromRefresh) {
        return homePage;
      }
    }

    if (path === '/billing' && store?.user?.subscription?.plan === 'IO') {
      return homePage;
    }

    return path;
  };

  const checkClientPages = (path) => {
    const { tokenRole: role } = useToken();
    const isClient = role.value === 'client';

    if (path.replaceAll('/', '') === '/'.replaceAll('/', '') && isClient) {
      return '/settings';
    }

    if (path.includes('/settings') && isClient) {
      return path;
    }

    if (path.includes('/settings') && !isClient) {
      return '/';
    }

    return path;
  };

  const checkWalletPages = async (path) => {
    const { isWalletConnected } = useWallet();
    if (path === '/wallet-setup' && isWalletConnected.value) {
      return '/wallet';
    }
    if (path === '/wallet' && !isWalletConnected.value) {
      return '/wallet-setup';
    }

    return path;
  };

  return {
    checkHomePage,
    checkCommonPages,
    checkRelatedRolesToPages,
    checkDisabledPages,
    checkClientPages,
    checkWalletPages,
  };
};

export default guards;
