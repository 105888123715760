import { defineStore } from 'pinia';

import { coinsSortWeight, supportedNetworksFamily } from '@/common/data';

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    assetsIncome: coinsSortWeight,
    data: {
      yearsList: [],
      income: {
        [(new Date()).getFullYear()]: [
          { month: 'January', data: {} },
          { month: 'February', data: {} },
          { month: 'March', data: {} },
          { month: 'April', data: {} },
          { month: 'May', data: {} },
          { month: 'June', data: {} },
          { month: 'July', data: {} },
          { month: 'August', data: {} },
          { month: 'September', data: {} },
          { month: 'October', data: {} },
          { month: 'November', data: {} },
          { month: 'December', data: {} },
        ],
      },
      // ...
    },
  }),
  getters: {
  },
  actions: {
    updateAssetsIncome(data) {
      supportedNetworksFamily.forEach((network) => {
        delete data[network];
      });
      this.assetsIncome = data;
    },
    updateTotalData(data) {
      this.data = data;
    },
  },
});
