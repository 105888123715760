import { computed } from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import { languages, fullCoinsArray } from '@/common/data';
import { i18n } from '@/plugins/localization';
import { useStore } from '@/store';
import { useToken } from '@/composables/useToken';
import { getLocalizedErrorMessage } from '@/composables/useFetch/helpers';
import useNotifications from '@/composables/useNotifications';
import { useUser } from '@/composables/useUser';

export function sequalize(fn) {
  let isPending = false;
  let lastPromise;

  return async () => {
    if (isPending) {
      return lastPromise;
    }
    isPending = true;
    lastPromise = fn();
    await lastPromise;
    isPending = false;
    return lastPromise;
  };
}

export const range = (start, end) => {
  if (end < start) {
    return [];
  }

  return Array(end - start + 1).fill().map((el, i) => start + i);
};

export const makeFirstLetterUppercased = (str) => (str ? `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}` : '');

export const isBase64String = (str) => {
  if (!str) {
    return false;
  }

  const res = str.match(/data:\w+\/[a-zA-Z+\-.]+;base64,/gm);

  return res?.length === 1;
};

export const isJWT = (str) => {
  if (!str) {
    return false;
  }

  const res = str.match(/[\w-]+\.[\w-]+\.[\w-]+/gm);

  return res?.length === 1;
};

export const hexToRGB = (val) => {
  if (!val) {
    return undefined;
  }

  const color = val[0] === '#' ? val.slice(1) : val;

  if (color.length !== 6) {
    return undefined;
  }

  const aRgbHex = color.match(/.{1,2}/g);

  // eslint-disable-next-line consistent-return
  return [
    parseInt(aRgbHex[0], 16),
    parseInt(aRgbHex[1], 16),
    parseInt(aRgbHex[2], 16),
  ];
};

export const runCronJob = (end, callback) => {
  const intervalId = setInterval(() => {
    const now = dayjs();

    if (now >= dayjs(end)) {
      clearInterval(intervalId);
      callback();
    }
  }, 10000);

  return intervalId;
};

export const listToArray = (list, key) => {
  const result = [];

  if (!list) {
    return result;
  }

  Object.keys(list).forEach((el) => {
    result.push({
      [key]: el,
      data: list[el],
    });
  });

  return result;
};

export const randomInteger = (min, max, notEqualsTo = undefined) => {
  const number = Math.floor(min + Math.random() * (max + 1 - min));

  if (number === notEqualsTo) {
    return randomInteger(min, max, notEqualsTo);
  }

  return number;
};

export const getRandomString = () => Math.random().toString(36).slice(2, 9);

export const truncateLongString = (
  str,
  {
    threshold,
    startLength,
    endLength,
    dots,
  } = {
    threshold: 42,
    startLength: 20,
    endLength: 20,
    dots: '...',
  },
) => {
  if (!str) {
    return '';
  }

  if (str.length <= threshold) {
    return str;
  }

  const start = str.substring(0, startLength);
  const end = str.substring(str.length - endLength);

  return `${start}${dots}${end}`;
};

export const truncateString = (
  str,
  { maxLength } = { maxLenght: 20 },
) => {
  if (!str) {
    return '';
  }

  if (str.length <= maxLength) {
    return str;
  }

  const halfLength = Math.floor(20 / 2);
  const start = str.slice(0, halfLength);
  const end = str.slice(-halfLength);
  return `${start}...${end}`;
};

export const getSystemLanguage = () => {
  const sysLang = (navigator.language || navigator.userLanguage)?.substring(0, 2) || 'en';

  const availableLanguages = languages.filter((el) => {
    if ('soon' in el) {
      return false;
    }
    return true;
  }).map((el) => el.value);

  return availableLanguages.includes(sysLang) ? sysLang : languages[0].value;
};

export const getStatusHints = (row) => {
  const isUnderpaidAndExpired = computed(() => (row.value?.state === 'EXPIRED_WHILE_UNDERPAID' || row.value?.state === 'UNDERPAID_EXPIRED_WITH_TWO_TX') && row.value?.status === 'EXPIRED');
  const isCompleteManual = computed(() => row.value?.state === 'COMPLETE_MANUAL' && row.value?.status === 'COMPLETE');
  const isExpiredWhileConfirmed = computed(() => row.value?.state === 'EXPIRED_WHILE_CONFIRMING' && row.value?.status === 'EXPIRED');

  return {
    isUnderpaidAndExpired,
    isCompleteManual,
    isExpiredWhileConfirmed,
  };
};

export const getPeriodAgo = (date) => {
  if (!date) {
    return '';
  }

  dayjs.extend(relativeTime);
  return dayjs(date).fromNow();
};

export const checkScrollYEnd = (event, cb) => {
  if (event.target.scrollHeight - (event.target.scrollTop + 1) <= event.target.clientHeight) {
    cb();
  }
};

export const copyTextToClipboard = async (text) => {
  try {
    await navigator.clipboard.writeText(text.replace('\n', ''));
  } catch (error) {
    console.error("CLIPBOARD FUNCTION DOESN'T EXIST");
  }
};

export const updateAppVersion = (val) => {
  const { isAdminRole } = useToken();

  if (isAdminRole.value || !val) {
    return;
  }

  const { user } = useUser();

  const [version, uid] = val.split(',');

  const store = useStore();

  if (version && store.lastResponse?.feVersion !== version) {
    const isRefreshNeeded = store.lastResponse?.feVersion && (user.value?.uid === uid || !uid);

    store.$patch({
      lastResponse: {
        feVersion: version,
      },
    });

    if (isRefreshNeeded) {
      window.location.reload(true);
    }
  }
};

export const downloadAsFile = ({ data, filename = 'data', type = 'txt' }) => {
  const file = new Blob([data], { type });

  const linkTag = document.createElement('a');
  const url = URL.createObjectURL(file);
  linkTag.href = url;
  linkTag.download = `${filename}.${type}`;
  document.body.appendChild(linkTag);
  linkTag.click();
  setTimeout(() => {
    document.body.removeChild(linkTag);
    window.URL.revokeObjectURL(url);
  }, 0);
};

export const pushFileErrorNotification = (error) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const text = event.target.result;

    try {
      const res = JSON.parse(text);
      const localizedErrorMessage = getLocalizedErrorMessage(res.message);
      const { addNotification } = useNotifications();
      addNotification({
        text: localizedErrorMessage,
        config: { color: 'warning', duration: 5000 },
      });
    } catch (err) {
      console.error('Error parsing JSON:', err);
    }
  };

  reader.readAsText(error.response.data);
};

export const getCurrency = (currency) => {
  const selectedCurrency = fullCoinsArray.find((coin) => coin.value === currency);
  const textCurrency = selectedCurrency?.isPrimary ? selectedCurrency.value : `${selectedCurrency?.name} (${selectedCurrency?.family})`;

  if (currency === 'ETH_ETH') {
    return selectedCurrency.shortValue;
  }

  return textCurrency;
};

export const pushNetworkErrorNotification = () => {
  const { t } = i18n.global;
  const { addNotification } = useNotifications();
  addNotification({
    text: t('errors.CONNECTION_LOST'),
    config: { color: 'warning', duration: 5000 },
  });
};

export const checkRequest = (rawRequest) => {
  const request = rawRequest;
  if (request instanceof Object) {
    Object.keys(request).forEach((key) => {
      if (typeof request[key] === 'string') {
        request[key] = request[key].trim();
      }
    });
  }
  return request;
};

export const round = (num) => Math.round(num * 1000) / 1000;
