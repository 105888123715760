import serviceRoutes from './service';

/* eslint-disable */
const getRouterPaths = () => {
  if (process.env.VUE_APP_BUILD_MODE === 'admin') {
    const adminRoutes = require('./admin').default;
    return [
      ...adminRoutes,
      ...serviceRoutes,
    ];
  }
  if (process.env.VUE_APP_BUILD_MODE === 'dnd') {
    const dndRoutes = require('./dnd').default;

    return [
      ...dndRoutes,
      ...serviceRoutes,
    ];
  }

  if (process.env.VUE_APP_BUILD_MODE === 'default') {
    const mainRoutes = require('./main').default;
    const merchantRoutes = require('./merchant').default;

    return [
      ...mainRoutes,
      ...merchantRoutes,
      ...serviceRoutes,
    ];
  }
  return [];
};

export const routes = getRouterPaths();

// DEBUG ROUTES
// console.table(routes.map((route) => ({
//   path: route.path,
//   name: route.name,
//   ...route.meta,
//   roles: route.meta?.roles?.join(', '),
// })));
