<template>
  <div class="service">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.service {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/images/bg_about.svg") no-repeat center center;
  background-size: auto 150%;
  padding: 140px 120px 120px 120px;

  @include mq('tablet') {
    padding: 140px 60px 40px 60px;
  }

  @include mq('mobile') {
    padding: 120px 40px 40px 40px;
  }
}
</style>
