import { defineStore } from 'pinia';
import dayjs from 'dayjs';

const getCurrentSticky = (stickies, audience) => {
  const allAudienceStickies = stickies.filter((item) => item.audience.includes(audience) && item.isHidden !== true);

  const sticky = allAudienceStickies.reduce((prev, current) => (dayjs(current.createdAt).isAfter(dayjs(prev.createdAt)) > 0 ? current : prev), allAudienceStickies[0]);

  if (sticky) {
    if (dayjs(sticky.revokeTime).diff(dayjs()) > 0) {
      return sticky;
    }
  }

  return {};
};

const mergeAndRemoveDuplicates = (array1, array2) => array1.concat(array2).reduce((acc, current) => {
  // eslint-disable-next-line no-underscore-dangle
  if (!acc.find((obj) => obj._id === current._id)) {
    acc.push(current);
  }
  return acc.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());
}, []);

export const useStickiesStore = defineStore('stickies', {
  state: () => ({
    public: [],
    active: [],
    history: [],
    historyPagination: {},
    stickiesSelectedYear: dayjs().year(),
    stickiesStartingYear: null,
    isHistoryLoaded: false,
    isStickiesLoaded: false,
  }),
  actions: {
    setLoadedState(state) {
      this.isHistoryLoaded = state;
    },
    setHistory(items, pagination = {}) {
      if (items.length) {
        this.stickiesSelectedYear = dayjs(items[0].createdAt).year();
        this.history = mergeAndRemoveDuplicates(this.history, items);
        this.historyPagination = pagination;
      }
    },
    clearHistory() {
      this.stickiesSelectedYear = dayjs().year();
      this.history = [];
      this.historyPagination = {};
    },
    setActive(items) {
      this.active = items;
    },
    setPublic(items) {
      // eslint-disable-next-line no-underscore-dangle
      const hideIds = this.public.filter((item) => item.isHidden).map((item) => item._id);

      items.forEach((item, i) => {
        // eslint-disable-next-line no-underscore-dangle
        if (hideIds.includes(item._id)) {
          items[i].isHidden = true;
        }
      });

      this.public = items;
    },
    setStickiesStartingYear(year) {
      this.stickiesStartingYear = year;
    },
    addActive(item) {
      this.active.push(item);
    },
    getVisitorsSticky() {
      return getCurrentSticky(this.public, 'VISITORS');
    },
    getMerchantsSticky() {
      return getCurrentSticky(this.public, 'MERCHANTS');
    },
    markStickyHidden(id) {
      this.public = this.public.map((item) => {
        // eslint-disable-next-line no-underscore-dangle
        if (item._id === id) {
          return { ...item, isHidden: true };
        }

        return item;
      });
    },
  },

  persist: true,
});
