import { createApp } from 'vue';

import { i18n } from '@/plugins/localization';
import { initSentry } from '@/plugins/sentry';
import { initStore } from '@/store';
import { registerComponents } from '@/plugins/registerComponents';
import { router } from '@/router';
import App from '@/App/App.vue';
import { preloadIcons } from '@/utils/preloadIcons';

import '@/styles/main.scss';

export const app = createApp(App)
  .use(router)
  .use(i18n);

initSentry(app);
initStore(app);
registerComponents(app);
preloadIcons();

app.mount('#app');
