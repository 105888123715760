import { APP_ROLES } from '@/common/data';

export default [
  {
    path: '/test',
    name: 'TRANSLATIONS',
    component: () => import('@/views/TestLocales.vue'),
    meta: {
      layout: 'EmptyLayout',
      roles: [APP_ROLES.USER],
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NOT_FOUND',
    meta: {
      layout: 'EmptyLayout',
    },
    component: () => import('@/views/service/NotFound.vue'),
  },
];
