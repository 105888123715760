import { getMerchantProfile } from '@/api/merchant';
import { useAdminStore, useStore } from '@/store';
import { useFetch } from '@/api';
import { useLang } from '@/composables/useLang';
import { useToken } from '@/composables/useToken';
import { APP_ROLES } from '@/common/data';

const readAdminProfile = async () => {
  const { axiosWrapper } = useFetch();
  const { admin, initProfile } = useAdminStore();
  const { setLanguage } = useLang();

  const hasData = Object.keys(admin).length > 0;

  if (!hasData) {
    const { data, isSuccess } = await axiosWrapper({
      type: 'get',
      url: '/settings/admin/profile',
    });

    if (isSuccess) {
      initProfile(data);
      setLanguage(data.language);
    }
  }
};

export const actions = (toRoute) => {
  const store = useStore();
  const isOtherPage = ['ADMIN_SIGN_IN', 'ADMIN_SIGN_UP'].includes(toRoute.name);
  const isLanding = toRoute.name === 'LANDING';

  const readProfile = () => {
    const { isAdminRole, isMerchantRole } = useToken();
    const isClientAccess = toRoute?.meta?.roles?.includes(APP_ROLES.CLIENT) || toRoute?.meta?.roles === undefined;

    if (!isOtherPage && isAdminRole.value) {
      readAdminProfile();
    }

    if (!isOtherPage && isMerchantRole.value && !isClientAccess) {
      getMerchantProfile();
    }
  };

  const readGlobalSettings = async () => {
    if (isLanding) {
      try {
        const { axiosWrapper } = useFetch();

        const { data } = await axiosWrapper({
          type: 'get',
          url: '/settings/admin/signup',
          isErrorNotification: false,
        });

        store.$patch({
          preferences: {
            isSignUpEnabled: data?.isSignUpEnabled,
          },
        });

        return { data };
      } catch (error) {
        console.error(error);
      }
    }
    return null;
  };

  return {
    readProfile,
    readGlobalSettings,
  };
};

export default actions;
