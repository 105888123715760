export const userFields = {
  baseCurrency: '',
  bitgoAddresses: [],
  companyName: '',
  cycle: '',
  isEmailVerified: true,
  isEmailChanged: false,
  totpStatus: '',
  settings: {},
  plan: '',
  role: '',
  status: '',
  uid: '',
  walletType: '',
  profileAvatar: '',
  profileOriginalAvatar: '',
  quickLogin: '',
  language: '',

  clients: [],
  email: '',
  payMeClients: [],
  payMePayments: [],
  whiteListIPs: [],

  username: '',
  isSuspended: '',
  createdAt: '',
  isInvitationExpired: false,
  isRegistrationCompleted: false,
  subscription: {},
};
