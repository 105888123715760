import { APP_ROLES } from '@/common/data';

const authRoutes = [
  {
    path: '/',
    name: 'ADMIN_SIGN_IN',
    meta: {
      layout: 'AdminAuth',
    },
    component: () => import('@/views/admin/Auth/AdminLogin/AdminLogin.vue'),
  },
  {
    path: '/admin/invite/:hash',
    name: 'REGISTRATION',
    meta: {
      layout: 'AdminAuth',
    },
    component: () => import('@/views/admin/Auth/AdminRegistration/AdminRegistration.vue'),
  },
  {
    path: '/admin-link-expired',
    name: 'ADMIN_LINK_EXPIRED',
    meta: {
      layout: 'AdminAuth',
    },
    beforeEnter: (to, from) => {
      if (from.path === '/') {
        return { path: '/' };
      }
      return true;
    },
    component: () => import('@/views/admin/Auth/AdminRegistration/LinkExpired.vue'),
  },
];

const innerPages = [
  {
    path: '/sales',
    name: 'SALES',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.sales',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/AdminSales'),
  },
  {
    path: '/merchants',
    name: 'MERCHANTS',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.merchants',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/AdminMerchants'),
  },
  {
    path: '/staff',
    name: 'STAFF',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.staff',
      roles: [APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/AdminStaff'),
  },
  {
    path: '/profile',
    name: 'ADMIN_PROFILE',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.profile',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/AdminProfile'),
  },
  {
    path: '/stickies',
    name: 'STICKIES',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.stickies',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/AppStiсkies'),
  },
  {
    path: '/settings',
    name: 'SETTINGS',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.settings',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/AdminSettings'),
  },
  {
    path: '/reports',
    name: 'REPORTS',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.reports',
      roles: [APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/AdminReports'),
  },
  {
    path: '/eth_flush',
    name: 'ETH Flush',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.ethFlush',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
      family: 'ETHEREUM',
    },
    component: () => import('@/views/admin/FlushPayment'),
  },
  {
    path: '/tron_flush',
    name: 'TRON Flush',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.tronFlush',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
      family: 'TRON',
    },
    component: () => import('@/views/admin/FlushPayment'),
  },
  {
    path: '/bsc_flush',
    name: 'BSC Flush',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.bscFlush',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
      family: 'BSC',
    },
    component: () => import('@/views/admin/FlushPayment'),
  },
  {
    path: '/reset_cache',
    name: 'Cache Reset',
    meta: {
      layout: 'Dashboard',
      headerTitle: 'sidebar.resetCache',
      roles: [APP_ROLES.ADMIN, APP_ROLES.SUPER_ADMIN],
    },
    component: () => import('@/views/admin/CacheReset'),
  },
];

const admin = [
  ...authRoutes,
  ...innerPages,
];

export default admin;
