import { computed } from 'vue';
import { defineStore } from 'pinia';

import { coloredLog } from '@/utils/coloredLog';
import { useAdminStore, useStore } from '@/store';

export const useTablesStore = defineStore('tables', {
  state: () => ({
    tablesSettings: {},
    shouldHardRefresh6: true,
  }),
  actions: {
    getCurrentTable({ moduleName }) {
      return this.tablesSettings[this.uid] && this.tablesSettings[this.uid][moduleName];
    },
    getTableColumns({
      moduleName,
      columnName,
      field,
    }) {
      return this.tablesSettings[this.uid]
        && this.tablesSettings[this.uid][moduleName]
        && this.tablesSettings[this.uid][moduleName].columns
        && this.tablesSettings[this.uid][moduleName].columns[columnName]
        && this.tablesSettings[this.uid][moduleName].columns[columnName][field];
    },
    setColumnWidth(moduleName, index, val) {
      this.columns[moduleName][index].width = val;
    },
    setColumnVisibility(moduleName, index, val) {
      this.columns[moduleName][index].isShow = val;
    },
    addColumnWhensItWasAddedByDeveloper({ columns, moduleName }) {
      try {
        if (columns) {
          columns.forEach((col) => {
            const hasNoColumnAfterAddInCodebase = !this.tablesSettings[this.uid][moduleName].columns[col.name];
            if (hasNoColumnAfterAddInCodebase) {
              coloredLog(`${col.name} COLUMN WAS INITIALIZED AND ADDED TO STORE`);
              this.tablesSettings[this.uid][moduleName].columns[col.name] = col;
            }

            // case when column was hidden, but developer made this column permanent
            // so column should be forced visible
            const isForcedShow = col.isForcedShow;
            if (isForcedShow) {
              this.tablesSettings[this.uid][moduleName].columns[col.name] = {
                ...col,
                isShow: true,
              };
            }
          });
        }
      } catch (error) {
        console.warn('hasNoColumnAfterAddInCodebase warning when table initialization');
      }
    },
    initTable({ moduleName, columns }) {
      const hasNoKeys = (obj) => (obj ? !Object.keys(obj).length : true);

      if (hasNoKeys(this.tablesSettings)) {
        this.tablesSettings = {
          [this.uid]: {},
        };
      }
      if (hasNoKeys(this.tablesSettings[this.uid])) {
        this.tablesSettings[this.uid] = {};
      }
      if (!(this.tablesSettings[this.uid][moduleName])) {
        this.tablesSettings[this.uid][moduleName] = {};
        if (columns) {
          this.tablesSettings[this.uid][moduleName].columns = {};
          columns.forEach((column) => {
            this.tablesSettings[this.uid][moduleName].columns[column.name] = column;
          });
        }
      } else {
        this.addColumnWhensItWasAddedByDeveloper({ columns, moduleName });
      }
    },
    resetDateFilters() {
      if (this.tablesSettings[this.uid]) {
        Object.entries(this.tablesSettings[this.uid]).forEach((element) => {
          const [moduleName, value] = element;
          if (value.dateFrom) {
            delete this.tablesSettings[this.uid][moduleName].dateFrom;
          }
          if (value.dateTo) {
            delete this.tablesSettings[this.uid][moduleName].dateTo;
          }
        });
      }
    },
    resetPageDateFilters(moduleName) {
      try {
        delete this.tablesSettings[this.uid][moduleName].dateFrom;
        delete this.tablesSettings[this.uid][moduleName].dateTo;
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    uid() {
      const store = useStore();
      const adminStore = useAdminStore();
      const uid = computed(() => store.user.uid || adminStore.admin.username);
      return uid.value;
    },
  },
  persist: true,
});

export const updateTableColumn = ({
  moduleName,
  columnName,
  field,
  value,
}) => {
  const store = useTablesStore();

  const uid = computed(() => store.uid);

  store.$patch({
    tablesSettings: {
      [uid.value]: {
        [moduleName]: {
          columns: {
            [columnName]: {
              [field]: value,
            },
          },
        },
      },
    },
  });
};

export const updateTable = ({ moduleName, field, value }) => {
  const store = useTablesStore();

  const uid = computed(() => store.uid);

  store.$patch({
    tablesSettings: {
      [uid.value]: {
        [moduleName]: {
          [field]: value,
        },
      },
    },
  });
};
