import Decimal from 'decimal.js';
import { maskitoNumberOptionsGenerator } from '@maskito/kit';

import { useUser } from '@/composables/useUser';
import { useSettings } from '@/composables/useSettings';
import { useAdmin } from '@/composables/useAdmin';
import { NUMBER_SEPARATORS, numberTypes } from '@/common/data';

export const roundValue = ({ value, decimals = 2, strategy = 'c' }) => {
  // We use two strategies for rounding 'c' and 'd' from: http://academic.brooklyn.cuny.edu/geology/leveson/core/linksa/roundoff.html
  const params = strategy === 'c' ? Decimal.ROUND_HALF_EVEN : undefined;

  return Number(new Decimal(value).toDecimalPlaces(decimals, params));
};

export const addThousandsSeparators = ({ val, separator = 'comma', baseCurrency = null }) => {
  if (separator === 'normal') {
    return val;
  }

  const { user } = useUser();
  const locale = (baseCurrency === 'INR' || user?.value?.baseCurrency === 'INR') ? 'en-IN' : 'en-US';

  const intSeparator = separator === 'comma' ? ',' : '.';
  const decimalSeparator = separator === 'comma' ? '.' : ',';

  if (val) {
    const [integer, decimal] = val.toString().split('.');

    const integerFormatted = (new Intl.NumberFormat(locale).format(integer)).replace(/,/g, intSeparator);

    return `${integerFormatted}${decimal ? `${decimalSeparator}${decimal}` : ''}`;
  }

  return val;
};

export const formatInrNumber = (val, numberFormat, decimalSeparator) => {
  let res = val;
  const rawVal = parseSeparatedNumber(val, numberFormat);

  if (val.length >= 2 && val[val.length - 1] === decimalSeparator) {
    return res;
  }

  if (rawVal >= 1000) {
    res = addThousandsSeparators({
      val: rawVal,
      separator: numberFormat,
      baseCurrency: 'INR',
    });
  }

  return res;
};

const truncateString = (inputString, symbol) => {
  const symbolIndex = inputString.indexOf(symbol);

  if (symbolIndex === -1 || inputString.length - symbolIndex <= 2) {
    return inputString;
  }

  return inputString.substring(0, symbolIndex + 3);
};

export const getMask = (currency, numberFormat) => {
  if (currency === 'INR') {
    return {
      mask: /[\d.,]+/,
      postprocessors: [
        ({ value, selection }) => {
          let newValue = formatInrNumber(value, numberFormat, NUMBER_SEPARATORS[numberFormat].decimalSeparator);
          const newSelection = selection;

          if (numberFormat !== 'normal') {
            newSelection[0] = selection[0] + (newValue.length - value.length);
            newSelection[1] = selection[1] + (newValue.length - value.length);
          }

          if (newValue.length <= 4 && newValue.includes(NUMBER_SEPARATORS[numberFormat].thousandSeparator)) {
            newValue = newValue.replace(NUMBER_SEPARATORS[numberFormat].thousandSeparator, '');
          }

          newValue = truncateString(newValue, NUMBER_SEPARATORS[numberFormat].decimalSeparator);

          return {
            value: newValue,
            selection: newSelection,
          };
        },
      ],
    };
  }

  return maskitoNumberOptionsGenerator({
    precision: 2,
    min: 0,
    thousandSeparator: NUMBER_SEPARATORS[numberFormat].thousandSeparator,
    decimalSeparator: NUMBER_SEPARATORS[numberFormat].decimalSeparator,
  });
};

export const getShortValWithSuffix = (val, baseCurrency) => {
  const { user } = useUser();
  const locale = (baseCurrency === 'INR' || user?.value?.baseCurrency === 'INR') ? 'en-IN' : 'en-US';

  if (locale === 'en-IN') {
    switch (true) {
      case val >= 10000000:
        return [val / 10000000, 'C'];
      case val >= 100000:
        return [val / 100000, 'L'];
      default:
        return [val, ''];
    }
  }

  switch (true) {
    case val >= 1000000000:
      return [val / 1000000000, 'B'];
    case val >= 1000000:
      return [val / 1000000, 'M'];
    case val >= 10000:
      return [val / 1000, 'K'];
    default:
      return [val, ''];
  }
};

export const formatNumber = ({
  value,
  format = '',
  numberType = '',
  isRaw = false,
  isStat = false,
  isInr = false,
}) => {
  const { numberFormat: mNumformat } = useSettings();
  const { numberFormat: aNumformat } = useAdmin();

  const numberFormat = mNumformat.value || aNumformat.value;

  let res = value;

  if (value === undefined) {
    return undefined;
  }

  if (!isRaw) {
    switch (numberType) {
      case numberTypes.INT:
        res = Math.round(res);
        break;
      case numberTypes.INT00:
        res = `${Math.round(res)}.00`;
        break;
      case numberTypes.FLOAT2:
        res = Math.round((res + Number.EPSILON) * 100) / 100;
        break;
      case numberTypes.FLOAT4:
        res = Math.round((res + Number.EPSILON) * 10000) / 10000;
        break;
      case numberTypes.FLOAT5:
        res = Math.round((res + Number.EPSILON) * 100000) / 100000;
        break;
      case numberTypes.TRUNC2:
        res = Math.floor(res * 100) / 100;
        break;
      case numberTypes.TRUNC4:
        res = Math.floor(res * 10000) / 10000;
        break;
      default:
        res = Number(res);
    }
  }

  if (isRaw && isStat) {
    const [shortVal, suffix] = getShortValWithSuffix(res);

    res = roundValue({ value: shortVal, decimals: res < 10000 ? 2 : 1 });

    if ((format && format !== 'normal') || (numberFormat && numberFormat !== 'normal')) {
      res = addThousandsSeparators({ val: res, separator: format || numberFormat.value, baseCurrency: isInr ? 'INR' : null });
    }

    return `${res}${suffix}`;
  }

  if ((format && format !== 'normal') || (numberFormat && numberFormat !== 'normal')) {
    res = addThousandsSeparators({ val: res, separator: format || numberFormat.value, baseCurrency: isInr ? 'INR' : null });
  }

  return res;
};

export const parseSeparatedNumber = (val, format) => {
  if (val && Number.isInteger(val)) {
    return val;
  }

  if (val && format === 'comma') {
    return parseFloat(val.split(',').join(''));
  }

  if (val && format === 'point') {
    return parseFloat(val.split('.').join('').replace(',', '.'));
  }

  return parseFloat(val);
};
