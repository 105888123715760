/* eslint-disable import/order */

import { currencyList } from '@/common/data';

import arrowRight from '@/assets/icons/arrow-basic.svg';
import close from '@/assets/icons/x-close.svg';
import loader from '@/assets/icons/loader-animated.svg';

import planPro from '@/assets/icons/plan-pro.svg';
import planBusiness from '@/assets/icons/plan-business.svg';
import planBlack from '@/assets/icons/plan-black.svg';
import copy from '@/assets/icons/copy.svg';

import switchLogo from '@/assets/icons/switch-logo.svg';
import atEmail from '@/assets/icons/at-email.svg';
import payments from '@/assets/icons/payments.svg';
import dashboard from '@/assets/icons/dashboard.svg';
import clients from '@/assets/icons/clients.svg';
import subscriptions from '@/assets/icons/subscriptions.svg';
import question from '@/assets/icons/info-question-circle-filled.svg';
import options from '@/assets/icons/options.svg';
import clockIcon from '@/assets/icons/clock-icon.svg';

import ethCircle from '@/assets/icons/eth-circle.svg';
import ethDisabled from '@/assets/icons/eth-disabled.svg';
import ethereumCircle from '@/assets/icons/ethereum-circle.svg';
import ethereumDisabled from '@/assets/icons/ethereum-disabled.svg';
import usdcCircle from '@/assets/icons/usdc-circle.svg';
import usdcDisabled from '@/assets/icons/usdc-disabled.svg';
import usdtCircle from '@/assets/icons/usdt-circle.svg';
import usdtDisabled from '@/assets/icons/usdt-disabled.svg';

import btcCircle from '@/assets/icons/btc-circle.svg';
import btcDisabled from '@/assets/icons/btc-disabled.svg';
import dashCircle from '@/assets/icons/dash-circle.svg';
import dashDisabled from '@/assets/icons/dash-disabled.svg';
import ltcCircle from '@/assets/icons/ltc-circle.svg';
import ltcDisabled from '@/assets/icons/ltc-disabled.svg';
import xmrCircle from '@/assets/icons/xmr-circle.svg';
import xmrDisabled from '@/assets/icons/xmr-disabled.svg';

const ethereumCoins = [
  ethCircle,
  ethDisabled,
  ethereumCircle,
  ethereumDisabled,
  usdtCircle,
  usdtDisabled,
  usdcCircle,
  usdcDisabled,
];
const coins = [
  btcCircle,
  btcDisabled,
  dashCircle,
  dashDisabled,
  ltcCircle,
  ltcDisabled,
  xmrCircle,
  xmrDisabled,
];

export const preloadIcons = () => {
  const preload = (src) => {
    const img = new Image();
    img.src = src;

    const preloadLink = document.createElement('link');
    preloadLink.href = src;
    preloadLink.rel = 'preload';
    preloadLink.as = 'image';
    document.head.appendChild(preloadLink);
  };

  [
    close,
    arrowRight,
    loader,
    planPro,
    planBusiness,
    planBlack,
    copy,
    atEmail,
    payments,
    dashboard,
    clients,
    subscriptions,
    question,
    options,
    switchLogo,
    clockIcon,
    ...ethereumCoins,
    ...coins,
  ]
    .forEach((icon) => {
      preload(icon);
    });

  currencyList.forEach((currency) => {
    preload(currency.icon);
  });
};
