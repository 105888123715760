import {
  createRouter, createWebHistory, onBeforeRouteUpdate, useRoute,
} from 'vue-router';
import { ref } from 'vue';

import actions from './actions';
import guards from './guards';
import { routes } from './routes';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

const areGuardsShouldBeMissed = ref(false);
export const routerPushWithoutGuards = (fn) => {
  areGuardsShouldBeMissed.value = true;
  fn();

  setTimeout(() => {
    areGuardsShouldBeMissed.value = false;
  }, 0);
};

router.beforeEach(async (to, from) => {
  try {
    const {
      checkHomePage,
      checkDisabledPages,
      checkWalletPages,
      checkCommonPages,
      checkRelatedRolesToPages,
      checkClientPages,
    } = guards(to, from);
    const {
      readProfile,
      readGlobalSettings,
    } = actions(to);

    readGlobalSettings();
    readProfile();

    let path = to.fullPath;

    if (areGuardsShouldBeMissed.value === false) {
      path = checkHomePage(path);
      if (['default', 'admin'].includes(process.env.VUE_APP_BUILD_MODE)) {
        path = await checkDisabledPages(path);
        path = checkCommonPages(path);
        path = checkRelatedRolesToPages(path);
        path = await checkWalletPages(path);
      }

      if (process.env.VUE_APP_BUILD_MODE === 'dnd') {
        path = checkClientPages(path);
      }

      if (to.fullPath !== path) {
        return path;
      }
    }
    return true;
  } catch (error) {
    console.error(error);
  }
  return false;
});

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload();
  }
});

export { router, onBeforeRouteUpdate, useRoute };
