import { router } from '@/router';
import { useTemporaryStore } from '@/store';
import useNotifications from '@/composables/useNotifications';

export const resetRegisteredState = () => {
  const { addNotification, t } = useNotifications();
  addNotification({ text: t('signup.notification.sessionExpired'), config: { color: 'error' } });

  const { registration } = useTemporaryStore();

  const hasRegistrationState = registration.value.currency || registration.value.companyName;

  if (hasRegistrationState) {
    router.push('/session-expired');
  } else {
    router.push('/');
  }
};

export const checkAccountWasPrunedHalder = (code) => {
  if (code === 404) {
    resetRegisteredState();
  }
};
