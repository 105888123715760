import { defineStore } from 'pinia';

export const useSystemStore = defineStore('system', {
  state: () => ({
    deviceId: null,
    salt: null,
  }),
  getters: {
    getDeviceId() {
      if (this.deviceId && this.salt) {
        return btoa(`${this.deviceId}-${this.salt}`);
      }
      return null;
    },
  },
  persist: true,
});
