import { defineStore } from 'pinia';

import { currencyList } from '@/common/data';

import { userFields } from '../data';

export const useStore = defineStore('main', {
  state: () => ({
    user: {},
    currencySign: '$',
    currencyValue: 'USD',
    authToken: null,
    refreshToken: null,

    uid: '',
    role: '',
    totpStatus: '',

    preferences: {
      isSignUpEnabled: null,
    },

    language: null,

    deviceId: '',

    lastResponse: {
      isServicePage: false,
      status: 200,
      statusText: 'OK',
      feVersion: null,
    },

    settings: {},
    walletData: {},
    rawWallets: [],
    processedWallets: {},
  }),

  actions: {
    initProfile(profile) {
      Object.entries(profile).forEach((param) => {
        const [name, value] = param;
        const hasField = Object.prototype.hasOwnProperty.call(userFields, name);
        if (hasField) {
          this.user[name] = value;
        }
      });
    },
    updateWalletConnectedState(settings) {
      this.walletData.isWalletConnected = settings.isWalletConnected;
      this.walletData.publicAddress = settings.publicAddress;
    },
    updateProfileAvatar({ profileAvatar, profileOriginalAvatar }) {
      this.user.profileAvatar = profileAvatar?.length ? `${profileAvatar}?${Math.random()}` : '';
      this.user.profileOriginalAvatar = profileOriginalAvatar;
    },
    clearProfile() {
      this.user = {};
      this.authToken = null;
      this.refreshToken = null;
      this.role = '';
    },
    getCurrencySign() {
      const { symbol, short } = currencyList.find((item) => item.short === this.user.baseCurrency);
      this.currencySign = symbol;
      this.currencyValue = short;
    },
    updateMerchantSettings(settings) {
      this.settings = settings;
    },
    setWalletData(walletData) {
      this.walletData = walletData;
    },
  },

  getters: {
    profileAvatar() {
      return this.user.profileAvatar;
    },
    profileQuickLogin() {
      return this.user.quickLogin;
    },
    currency() {
      return this.currencySign;
    },
    notifications() {
      return this.settings?.notifications;
    },
    wallets() {
      return this.processedWallets?.nonCustodialWallets;
    },
  },

  persist: true,
});
