import { ref } from 'vue';

import { listToArray, downloadAsFile } from '@/utils/functions';
import { useFetch } from '@/api';
import { useDashboardStore } from '@/store';

export const isFreshResponse = ref(false);

export const getTotalData = async (params = {}) => {
  const { axiosWrapper } = useFetch();
  const { data } = await axiosWrapper({
    type: 'get',
    url: '/merchants/dashboard/elastic',
    params: {
      fromCache: true,
      ...params,
    },
  });

  data.yearsList = Object.keys(data.income).map((el) => ({ text: el, value: el })).reverse();

  data.yearsList.forEach((el) => {
    if (!Array.isArray(data.income[el.value])) {
      data.income[el.value] = listToArray(data.income[el.value], 'month');
    }
  });

  const { updateTotalData } = useDashboardStore();
  updateTotalData(data);

  return data;
};

export const getAssetsIncome = async (params = {}) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/merchants/assets-income/elastic',
    params,
  });

  const { updateAssetsIncome } = useDashboardStore();
  updateAssetsIncome(data);

  return { data, isSuccess };
};

export const getIncomeStatement = async ({ name = 'statement', params = { dateFrom: null, dateTo: null } }) => {
  const { axiosWrapper, successHandler, t } = useFetch();
  const response = await axiosWrapper({
    type: 'get',
    url: '/merchants/statements',
    params: { ...params, isFreshResponse: true },
    responseType: 'blob',
  });

  if (response.result?.data) {
    downloadAsFile({
      data: response.result.data,
      filename: name,
      type: 'pdf',
    });

    successHandler(t('merchantDashboard.notification.invoiceWasDownloadedSuccess'));
  }
};
