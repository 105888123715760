import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { PiniaSharedState } from 'pinia-shared-state';

export const initStore = (app) => {
  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);
  pinia.use(
    PiniaSharedState({
      enable: true,
      initialize: true,
      type: 'localstorage',
    }),
  );

  app.use(pinia);
};
