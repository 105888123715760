<template>
  <Transition name="fade-absolute">
    <TooManyRequestsError v-if="isTooManyRequests" />
    <ServiceBackground v-else-if="error?.title">
      <ServicePage
        :title="error?.title"
        :description="error?.description"
      />
    </ServiceBackground>
  </Transition>
</template>

<script setup>
import { computed } from 'vue';

import { i18n } from '@/plugins/localization';

import ServiceBackground from './components/ServiceBackground.vue';
import ServicePage from './components/ServicePage.vue';
import TooManyRequestsError from './TooManyRequestsError.vue';

const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
});

const { t } = i18n.global;
const ERRORS = Object.freeze({
  ACCESS_RESTRICTED: {
    title: t('servicePage.accessRescticted.title'),
    description: t('servicePage.accessRescticted.description'),
  },
  UNDER_MAINTENANCE: {
    title: t('servicePage.underMaintenance.title'),
    description: t('servicePage.underMaintenance.description'),
  },
});

const error = computed(() => ERRORS[props.info?.statusText]);
const isTooManyRequests = computed(() => props.info?.statusText === 'TOO_MANY_REQUESTS');
</script>
