import { createI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';

import { getSystemLanguage } from '@/utils/functions';
import { useSettings } from '@/composables/useSettings';
import { useStore } from '@/store';
import en from '@/locales/en.json';
import ja from '@/locales/ja.json';
import ko from '@/locales/ko.json';
import ru from '@/locales/ru.json';
import zh from '@/locales/zh.json';

export const i18n = createI18n({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    ru,
    zh,
    ja,
    ko,
  },
  pluralRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru(choice, choicesLength) {
      /*
        0  поступлений
        1  поступление
        2  поступления
      */
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

export const initTranslations = (app) => {
  const { language } = useSettings();
  const store = useStore();

  const sysLang = getSystemLanguage();

  const appropriateLanguage = language.value || sysLang || 'en';

  store.$patch({ language: appropriateLanguage });

  i18n.global.locale.value = appropriateLanguage;
  dayjs.locale(appropriateLanguage);
  app.config.globalProperties.t = i18n.global.t;
};

export default i18n;
