<template>
  <div class="service__page">
    <div class="title">
      {{ props.title }}
    </div>

    <div class="description">
      {{ props.description }}
    </div>

    <div class="contacts">
      <a
        class="contacts__item"
        target="_blank"
        rel="noopener noreferrer"
        href="https://t.me/switch_to"
        :aria-label="t('ariaLabel.telegram')"
      >
        <AppIcon name="telegram" size="24px" />
      </a>
      <a
        class="contacts__item"
        target="_blank"
        rel="noopener noreferrer"
        href="https://twitter.com/switch767"
        :aria-label="t('ariaLabel.twitter')"
      >
        <AppIcon name="twitter" size="24px" />
      </a>
      <a class="contacts__item support" :href="emailLink">
        {{ email }}
      </a>
    </div>

    <a class="logo" href="/" :aria-label="t('ariaLabel.home')">
      <AppIcon name="switch-logo" size="90px" />
    </a>
  </div>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: 'Service Page',
  },
  description: {
    type: String,
    default: 'Service Page Description',
  },
});

const email = `support@${process.env.VUE_APP_PRODUCTION_URL}`;
const emailLink = `mailto:${email}`;
</script>

<style lang="scss" scoped>
.service__page {
  position: relative;
  height: 100%;

  :deep(.text) {
    display: block;
  }

  .title {
    font-size: 100px;
    line-height: 1.2;
    @include font-medium;

    @include mq('tablet') {
      font-size: 70px;
    }

    @include mq('mobile') {
      font-size: 33px;
    }
  }

  .description {
    margin-top: 20px;
    font-size: 27px;
    line-height: 1.2;
    opacity: 0.4;
    @include font-medium;

    @include mq('mobile') {
      font-size: 18px;
      line-height: 1.2;
    }
  }

  .contacts {
    margin-top: 60px;
    display: flex;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--color-black-01);
      color: var(--color-999999);
      width: 40px;
      height: 40px;
      border-radius: calc(40px * .5);
      margin-right: 20px;
      text-decoration: none;
      cursor: pointer;

      @include transition-base;

      &:hover {
        color: var(--color-black);

        :deep(.icon) {
          path {
            fill: var(--color-black);
          }
        }
      }
    }
  }

  .logo {
    position: absolute;
    bottom: 0;

    :deep(.icon) {
      @include mq('mobile') {
        width: 50px;
      }

      path {
        opacity: 0.2;
        @include transition-base('opacity');
        animation: pulse 3s ease infinite;
      }

      @keyframes pulse {
        0% { opacity: 0.2; }
        50% { opacity: 1; }
        100% { opacity: 0.2; }
      }
    }

    &:hover {
      cursor: pointer;

      :deep(.icon) {
        path {
          opacity: 1;
          animation-iteration-count: 0;
        }
      }
    }
  }
}

.support {
  font-size: 15px;
  line-height: 40px;
  width: auto !important;
  margin-right: 0 !important;
  padding: 0 20px;
}

:deep(.icon) {
  svg path {
    @include transition-base
  }
}
</style>
