// import Button from '@/components/UIKit/Button.vue';
import CustomScroll from '@/components/CustomScroll.vue';
import AppIcon from '@/components/Icon/AppIcon.vue';
import AppInput from '@/components/UIKit/AppInput.vue';
import AppText from '@/components/UIKit/AppText.vue';
import DateFormatter from '@/components/Helpers/DateFormatter.vue';
import NumberFormatter from '@/components/Helpers/NumberFormatter.vue';
import * as elementComponents from '@/components/ElementUI/registerElementComponents';

export const registerComponents = (app) => {
  // app.component('Button', Button);
  app.component('AppInput', AppInput);
  app.component('AppText', AppText);
  app.component('AppIcon', AppIcon);
  app.component('DateFormatter', DateFormatter);
  app.component('NumberFormatter', NumberFormatter);
  app.component('CustomScroll', CustomScroll);

  Object.entries(elementComponents.default).forEach((component) => {
    const [componentName, Component] = component;
    app.component(componentName, Component);
  });
};
